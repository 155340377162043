class AmeMh extends HTMLElement {
    
    constructor() {
        super();
    }

    connectedCallback() {
        this.config();

        //added class on selector-wrapper
        document.querySelector(this.selectorWrapper).classList.add('mh2021Page');
    }

    config() {
        //css
        let style = document.createElement("style");
        style.textContent = this.style();
        this.appendChild(style);
        
        //appendo il markup
        this.insertAdjacentHTML('beforeend', this.template())
    }

    template() {
        let template = `
            <div id="mh2021" class="mhInView">
                <div id="adv_LdbMastheadPush" class="mh2021Strip adv_concessionaria adv-desktop"></div>
            </div>
            <div id="mh2021Fake"></div>
        `;
        return template;
    }

    style() {

        const css = `
            :root {
                --altezzaMh2021: 33vw;
                --bgMh2021: #fff;
            }
            #mh2021 {
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                overflow: hidden;
                background: ${(this.bgMh) ? this.bgMh : 'var(--bgMh2021)'};
            }
            #mh2021.mhForeground {
                z-index: 9999;
                pointer-events: none;
            }
            #mh2021Fake {
                height: var(--altezzaMh2021);
                width: 90vw;
                position: absolute;
                top: 0;
                left: 5vw;
                overflow: hidden;
                z-index: -100;
            }
            .mh2021Page {
                transition: margin 300ms ease-in-out;
                position: relative;
                z-index: 1;
            }
            .mh2021Strip {
                width: 100vw;
                max-height: var(--altezzaMh2021);
                overflow: hidden;
                background: ${(this.bgMh) ? this.bgMh : 'var(--bgMh2021)'};
                display: flex;
                justify-content: center;
                align-items: flex-start;
                pointer-events: all;
            }
            @media all and (min-width: 1000px) {
                :root {
                    --altezzaMh2021 : 250px;
                }
            }
        `;

        return css;
    }

    get selectorWrapper() {
        return this.getAttribute('selector-wrapper');
    }
    
    get bgMh() {
        return this.getAttribute('bg-mh');
    }

    get disableStrip() {
        return this.getAttribute('disable-strip');
    }

    //strip_animation start
    static strip_animation() {

        let ameMhElement        = document.querySelector('ame-mh'),
            getEnableEvents     = ameMhElement.getAttribute('enable-events'),
            getViewTimeLimit    = Number(ameMhElement.getAttribute('view-time-limit')),
            getFirstDelay       = Number(ameMhElement.getAttribute('first-delay'));
            
        let debug = 1,
            viewTimeLimit = (getViewTimeLimit && getViewTimeLimit != "") ? getViewTimeLimit : 5000,
            firstDelay = (getFirstDelay && getFirstDelay != "") ? getFirstDelay : 3000;

        let mh = document.getElementById("mh2021"),
            mhFake = document.getElementById("mh2021Fake"),
            page = document.querySelector('.mh2021Page'),
            strip = document.querySelector(".mh2021Strip"),
            paddingStrip = document.querySelector("#padding-strip"),
            
            mhIntersection,
            pageSpacedInTop = 0,
            stripH = strip.offsetHeight || 0;

        if (debug == 1) localStorage.setItem("mh2021Debug", 1);

        let log = (msg, val) => {
            if (localStorage.getItem("mh2021Debug"))
            console.log("[mh2021] - " + msg, val);
        };

        if (stripH == 0) {
            log("Strip ad altezza 0 -> return false");
            return false;
        }

        if (typeof window.stripanimationrun !== "undefined") {
            console.warn(
                "[mh2021] - ATTENZIONE! Strip animation richiamata più volte!"
            );
            return false;
        }
        window.stripanimationrun = 1;

        if (getEnableEvents == "true") {
            if (window.gtag) {
                gtag("event", "Start", {
                    event_category: "mh2021",
                    event_label: document.location.href,
                    non_interaction: true,
                });
            } else if (window.ga) {
                ga('send', 'event', "mh2021", "Start", document.location.href, {
                    nonInteraction: true
                });
            } else {}
        }

        let mh2021PageInTopView = (motivo) => {
            log("mh2021PageInTopView", motivo); //log del motivo per cui do margine superiore alla pagina
            observer.disconnect();
            let mhH = mh.firstElementChild.offsetHeight;
            if(mhH <= 50) mhH = 0; //fix per webview fb. Per qualche motivo gira la strip_animation sulla 3x1. Impediamo di mettere margine alla pagina se non c'è uno slot consistente e 50px dovrebbero essere safe.
            let padding = 0
            // se c'è il blocco di padding aggiungo anche quello all'altezza
            if (paddingStrip) padding = paddingStrip.offsetHeight;
            page.style.marginTop = mhH + padding + "px";
            console.log("mhH:", mhH);
            pageSpacedInTop = 1;

            if (getEnableEvents == "true") {
                if (window.gtag) {
                    gtag("event", motivo, {
                        event_category: "mh2021",
                        event_label: document.location.href,
                        non_interaction: true,
                    });
                } else if (window.ga) {
                    ga('send', 'event', "mh2021", motivo, document.location.href, {
                        nonInteraction: true
                    });
                } else {}
            }
            
            /*document.body.removeEventListener('click',mh2021PageInTopView); //DA RIABILITARE IN CUI SI RIATTIVI L'INTERAZIONE UTENTE
            document.body.removeEventListener('keypress',mh2021PageInTopView);*/
        };

        //check iniziale
        setTimeout(() => {
            if (mhIntersection) {
                console.log('top view');
                mh2021PageInTopView("In top view all'inizio");
            }
        }, firstDelay + 100);

        //fix
        setTimeout(() => {
            console.log('top view');
            log("Fix inizio", performance.now());
            mh.classList.add("mhForeground");
            if (paddingStrip) {
                let mhH = mh.firstElementChild.offsetHeight;
                paddingStrip.style.setProperty('--margin-top-adv', mhH + 'px');
            }

        }, firstDelay + 400);

        //defix
        setTimeout(() => {
            log("Defix", performance.now());
            mh.classList.remove("mhForeground");
        }, firstDelay + viewTimeLimit + 400);

        //callback observer
        let cbMh = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (pageSpacedInTop == 0 && mhIntersection == false) {
                        mh2021PageInTopView("Ritorno in top view");
                    }
                    mhIntersection = true;
                } else {
                    mhIntersection = false;
                }
            });
        };

        //opzioni observer
        let optObs = {
            rootMargin: "0px",
            threshold: 1,
        };

        //dichiarazione observer
        let observer = new IntersectionObserver(cbMh, optObs);

        //start observer
        log("Start observer", "");
        observer.observe(mhFake);
    }
}
customElements.define("ame-mh", AmeMh);